import { gql } from "@apollo/client";

const LOG_OUT = gql`
  mutation logOut {
    logout(input: {}) {
      status
    }
  }
`;

export default LOG_OUT;
