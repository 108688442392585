import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import React, { ReactNode, createContext, useContext, useMemo } from "react";
import LOG_OUT from "../queries/LogOut";
import { navigate } from "gatsby";
import { client } from "../../lib/apolloClient";

export interface User {
  id: string;
  databaseId: number;
  firstName: string;
  lastName: string;
  email: string;
  capabilities: string[];
}

interface AuthData {
  loggedIn: boolean;
  user?: User;
  isUnlicensedUser: boolean;
  isBankUser: boolean;
  isCloudTalkUser: boolean;
  loading: boolean;
  error?: ApolloError;
  isCustomerDefault: boolean;
  logOut: () => void;
}

const DEFAULT_STATE: AuthData = {
  isUnlicensedUser: false,
  isCustomerDefault: true,
  isBankUser: false,
  isCloudTalkUser: false,
  loggedIn: false,
  user: undefined,
  loading: true,
  error: undefined,
  logOut: () => {},
};

const AuthContext = createContext(DEFAULT_STATE);

export const GET_USER = gql`
  query getUser {
    viewer {
      id
      databaseId
      firstName
      lastName
      email
      capabilities
    }
  }
`;

export function AuthProvider({ children }: { children: ReactNode }) {
  const { data, loading, error } = useQuery(GET_USER);

  const [logOutMutation, { loading: logOutLoading }] = useMutation(LOG_OUT, {
    onCompleted: () => {
      // navigate("/sign-in");
    },
  });

  const logOut = (): void => {
    client.writeQuery({
      query: GET_USER,
      data: {
        viewer: null, // Set the viewer to null
      },
    });
    logOutMutation();
  };

  const value = useMemo(() => {
    const user = data?.viewer;
    const isCustomerDefault = data?.viewer?.capabilities.includes("customer");
    const isUnlicensedUser = data?.viewer?.capabilities.includes(
      "customer_unlicensed",
    );
    const isBankUser = data?.viewer?.capabilities.includes("customer_bank");
    const isCloudTalkUser = data?.viewer?.capabilities.includes("cloudtalk");

    const loggedIn = logOutLoading ? false : Boolean(user);

    return {
      loggedIn,
      isUnlicensedUser,
      isCustomerDefault,
      isBankUser,
      isCloudTalkUser,
      user,
      loading: loading || logOutLoading,
      error,
      logOut,
    };
  }, [data, loading, error, logOutLoading]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => useContext(AuthContext);

export default useAuth;
